import usePrivateAxios from "@/common/api/usePrivateAxios";
import { ErrorResponse } from "@/common/types/errorResponse";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface UseUploadFiscalDocumentForConsumption {
  id: string;
  onSuccess?: (response: AxiosResponse) => void;
  onError?: (error: ErrorResponse) => void;
}

const useBillingEventUploadFiscalDocumentForConsumption = ({ id, onSuccess, onError }: UseUploadFiscalDocumentForConsumption) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['billingEventUploadFiscalDocument'],
    mutationFn: async (file: Blob) => {
      return await privateAxios.putForm(`/billing/events/${id}/upload_fiscal_document_for_consumption`, {
        event: {
          eventable: {
            fiscal_document_attributes: {
              xml: file,
            }
          },
        }
      });
    },
    onSuccess,
    onError,
  });
};

export default useBillingEventUploadFiscalDocumentForConsumption;
