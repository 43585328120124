import Button from '@/common/components/Button';
import Modal from '@/common/components/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { productPriceSchema, ProductPriceValidationSchema } from '../schemas/productPrice.schema';
import { FormTransformers } from '@/common/utils/formTransformers';
import { ProductPriceData } from '../types/productPrice.type';
import useUpdateProductPrice from '../hooks/useUpdateProductPrice';
import { formatCurrency } from '@/common/utils/formatters';
import Select from '@/common/components/Select';
import { useQueryClient } from '@tanstack/react-query';
import { ProductPricesTransformer } from '../services/productPricesTransformer';
import { CurrencyTextField } from '@/common/components/CurrencyTextField';

interface EditProductPriceModalProps {
  productPrice: ProductPriceData;
}

const EditProductPriceModal = ({ productPrice }: EditProductPriceModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { control, handleSubmit, setError, reset, formState: { errors } } = useForm<ProductPriceValidationSchema>({
    resolver: zodResolver(productPriceSchema),
  });

  const { mutate: updateProductPrice, isPending } = useUpdateProductPrice({
    id: productPrice.id,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productsPrices'] });
      setIsOpen(false);
    },
    onError: FormTransformers.errorsTransformer<ProductPriceValidationSchema>({
      setError,
      transformer: ProductPricesTransformer.errorsTransformer(),
    }),
  });

  const onSubmit: SubmitHandler<ProductPriceValidationSchema> = async (form) => updateProductPrice(form);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <>
      <Button variant="neutral" className="w-full text-left" onClick={() => setIsOpen(true)}>
        {formatCurrency(Number(productPrice.value) || 0)}
      </Button>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Adicionar novo produto"
        footer="Confirmar ajuste e salvar alteração"
        body={(
          <form className="flex flex-col gap-4">
            <Select
              label="Produto"
              markAsRequired
              value={{ value: productPrice.product.id, label: productPrice.product.display_name }}
              isDisabled
            />

            <CurrencyTextField
              value={productPrice?.value ? formatCurrency(Number(productPrice.value)) : ''}
              className="text-white-800"
              label="Valor antigo do produto"
              disabled
            />
            <Controller
              name="value"
              control={control}
              defaultValue={formatCurrency(Number(productPrice.value))}
              disabled={isPending}
              render={({ field }) => (
                <CurrencyTextField
                  label="Valor novo do produto"
                  markAsRequired
                  className="text-white-800 mt-4"
                  errorMessage={errors.value?.message}
                  isLoading={isPending}
                  {...field}
                />
              )}
            />
          </form>
        )}
        onConfirm={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default EditProductPriceModal;