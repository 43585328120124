import Box from '@/common/components/Box';
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper';
import { ShowHeader } from '@/common/components/ShowHeader';
import FeesSection from '@/features/company/components/Contract/FeesSection';
import InvoiceDetailsSection from '@/features/company/components/Contract/InvoiceDetailsSection';
import ProductsSection from '@/features/company/components/Contract/ProductsSection';
import { useMineCompany } from '@/features/company/hooks/useMineCompany';
import { useMineContract } from '@/features/company/hooks/useMineContract';
import { ChevronLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const CompanyContract = () => {
  const { data: company } = useMineCompany();

  const {
    data: contract,
    error: contractError = {} as any,
    refetch: refetchContract,
  } = useMineContract();

  return (
    <div>
      <ShowHeader
        logoUrl={company?.general_information.company_logo?.url}
        displayName={company?.general_information.fantasy_name}
        backTo="/company"
      />

      <Box className="flex flex-col gap-6 h-[calc(100vh-130px)] overflow-y-scroll">
        <div className="flex items-center gap-2">
          <Link to="/company/mine">
            <ChevronLeft size={32} strokeWidth={1} />
          </Link>

          <p className="text-[18px] font-bold">
            Contrato
          </p>
        </div>

        <ErrorMessageWrapper
          className="max-h-[calc(100vh-128px)]"
          statusCode={contractError?.status}
          refetchFunction={refetchContract}
        >
          <InvoiceDetailsSection 
            contract={contract}
          />

          <FeesSection
            contract={contract}
          />

          <ProductsSection
            contract={contract}
          />
        </ErrorMessageWrapper>
      </Box>
    </div>
  );
};

export default CompanyContract;