import { formatDate  } from "@/common/utils/formatters";
import { FiscalDocumentGroupData } from "../../types/fiscalDocumentGroup.type";
import { BriefcaseBusinessIcon } from "lucide-react";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";

const BillingResume = ({ fiscalDocumentGroup }: { fiscalDocumentGroup?: FiscalDocumentGroupData; }) => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-xl font-bold">Resumo da fatura</h3>

      <div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-8">
            {fiscalDocumentGroup?.opened_at && (
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Abertura</p>
                <p className="font-bold text-md">{formatDate(new Date(`${fiscalDocumentGroup?.opened_at} `))}</p>
              </div>
            )}

            {fiscalDocumentGroup?.closed_at && (
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Fechamento</p>
                <p className="font-bold text-md">{formatDate(new Date(`${fiscalDocumentGroup?.closed_at} `))}</p>
              </div>
            )}
          </div>

          {fiscalDocumentGroup?.client_branch && (
            <div className="flex gap-8">
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Empresa</p>

                <div className="flex items-center gap-2">
                  <ImageWithPlaceholder
                    placeholderIcon={BriefcaseBusinessIcon}
                    className="w-6 h-6"
                    iconClassName="w-4 h-4"
                    url={fiscalDocumentGroup?.client_branch?.general_information?.company_logo?.url}
                  />

                  <p>
                    {fiscalDocumentGroup?.client_branch?.general_information?.fantasy_name || '--'}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Filial</p>

                <p>
                  {fiscalDocumentGroup?.client_branch?.display_name || '--'}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingResume;
