import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import { BillingEventData } from "@/common/types/billing/event.type";
import { formatDateTime } from "@/common/utils/formatters";
import { BriefcaseBusinessIcon } from "lucide-react";

const EventResume = ({ event }: { event?: BillingEventData; }) => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-xl font-bold">Resumo da transação</h3>

      <div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-8">
            <div className="flex flex-col gap-1">
              <p className="italic font-light text-xs">Data e hora da transação</p>
              <p className="font-bold text-md">{event?.created_at && formatDateTime(new Date(event?.created_at))}</p>
            </div>
          </div>

          {event?.eventable?.client_branch && (
            <div className="flex gap-8">
              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Empresa</p>

                <div className="flex items-center gap-2">
                  <ImageWithPlaceholder
                    placeholderIcon={BriefcaseBusinessIcon}
                    className="w-6 h-6"
                    iconClassName="w-4 h-4"
                    url={event?.eventable?.client_branch?.general_information?.company_logo?.url}
                  />

                  <p>
                    {event?.eventable?.client_branch?.general_information?.fantasy_name || '--'}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <p className="italic font-light text-xs">Filial</p>

                <p>
                  {event?.eventable?.client_branch?.display_name || '--'}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventResume;
