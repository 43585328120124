import ListFilters from "@/common/components/ListFilters";
import ListHeader from "@/common/components/ListHeader";
import TextField from "@/common/components/TextField";
import useDebounce from "@/common/hooks/useDebounce";
import { Mapper } from "@/common/services/mapper";
import { SearchIcon, Table2 } from "lucide-react";
import Table from "@/common/components/Table";
import { useProductCategories } from "@/common/hooks/queries/useProductCategories";
import { ProductCategoryData } from "@/common/types/productCategory.type";
import { ProductPricesTransformer } from "../../services/productPricesTransformer";
import productPricesTableColumns from "../../constants/productPricesTableColumns.contant";
import { useProductPrices } from "../../hooks/useProductPrices";
import NewProductPriceModal from "../../components/NewProductPriceModal";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { ErrorMessageWrapper } from "@/common/components/ErrorMessageWrapper";

const ProductPricesList = () => {
  const { filterParams, setFilterParams } = useFilterParams();

  const [searchProductCategory, setSearchProductCategory] = useDebounce("");

  const { data: productCategoryOptions } = useProductCategories({
    filters: {
      search: searchProductCategory,
    },
    mapper: Mapper.mapToOptions<ProductCategoryData>({ valueFieldName: "id", labelFieldName: "display_name" }),
  });

  const {
    data: productPrices,
    meta,
    isFetching: productPricesIsFetching,
    error: productPricesError = {} as any,
    refetch: refetchProductPrices,
  } = useProductPrices({
    filters: {
      search: filterParams?.search || '',
      productCategoryId: filterParams?.productCategory?.value || '',
    },
    mapper: ProductPricesTransformer.tableTransformer(),
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<Table2 className="w-10 h-10" strokeWidth={1.5} />}
          title="Tabela de preços"
        />

        {!(productPricesIsFetching || productPricesError?.status === 403) && <NewProductPriceModal />}
      </div>

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={productPricesError?.status}
        refetchFunction={refetchProductPrices}
      >
        <div className="h-[calc(100vh-128px)] flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <TextField
              LeftIcon={<SearchIcon />}
              placeholder="Pesquisar"
              className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)] w-full"
              onChange={(e: any) => setFilterParams((params) => ({ ...params, search: e.target.value }))}
              defaultValue={filterParams?.search}
            />

            <ListFilters
              filters={[
                {
                  className: "",
                  onInputChange: setSearchProductCategory,
                  onChange: (e: any) => setFilterParams((params) => ({ ...params, productCategory: e })),
                  defaultValue: filterParams?.productCategory,
                  options: [{ label: "Todos", value: "" }, ...(productCategoryOptions || [])],
                  placeholder: "Serviços",
                },
              ]}
            />
          </div>

          <Table
            columns={productPricesTableColumns}
            isLoadingData={productPricesIsFetching}
            page={filterParams.page || 1}
            rows={productPrices}
            totalPages={meta.total_pages}
            totalCount={meta.total}
            hasPagination
            setPage={(p) => setFilterParams((params) => ({ ...params, page: p }))}
          />
        </div>
      </ErrorMessageWrapper>
    </div>
  );
};

export default ProductPricesList;
