import CardDetailFieldPair from "@/features/consumption/components/CardDetailFieldPair";
import { cardDetailsFieldForDisplay } from "../../constants/cardDetailsFieldForDisplay.constant";
import { cardTypeTranslation } from "@/common/constants/cards.constant";
import { formatCardNumber } from "@/common/utils/formatters";
import Box from "@/common/components/Box";
import { ClientConsumptionData } from "@/common/types/client/consumption.type";

const EventCardDetails = ({ card }: { card: ClientConsumptionData['card'] }) => {
  return (
    <div>
      <Box className="flex flex-col items-start rounded-lg gap-2 p-6 py-5">
        <div className="flex gap-4 items-center">
          <p className="font-light italic">
            {cardDetailsFieldForDisplay[card?.type as keyof typeof cardDetailsFieldForDisplay]}:
          </p>

          <p className="text-lg uppercase">
            {card?.equipment_field_for_display_value}
          </p>
        </div>

        <div className="bg-gray-disabled h-[2px] w-5 rounded" />

        <CardDetailFieldPair
          label="Cartao"
          value={card?.last_four_digits ? formatCardNumber(card.last_four_digits) : ''}
        />

        <CardDetailFieldPair
          label="Tipo de Cartao"
          value={cardTypeTranslation[card?.type as keyof typeof cardTypeTranslation]}
        />
      </Box>
    </div>
  );
};

export default EventCardDetails;
