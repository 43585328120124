import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper'
import ListFilters from '@/common/components/ListFilters'
import ListHeader from '@/common/components/ListHeader'
import Table from '@/common/components/Table'
import TextField from '@/common/components/TextField'
import useDebounce from '@/common/hooks/useDebounce'
import { useFilterParams } from '@/common/hooks/useFilterParams'
import { Mapper } from '@/common/services/mapper'
import { TicketData, TicketDataTableItem } from '@/common/types/support/ticket.type'
import { defaultColumns } from '@/features/support/constants/columns.constant'
import { options as statusOptions } from '@/features/support/constants/statusOptions.constant'
import { useSupportTickets } from '@/features/support/hooks/useSupportTickets'
import { SupportTicketsTransformer } from '@/features/support/services/supportTicketsTransformer'
import { SearchIcon, TicketIcon } from 'lucide-react'

const List = () => {
  const { filterParams, setFilterParams } = useFilterParams()

  const [searchName, setSearchName] = useDebounce(filterParams.name || '')

  const {
    data: supportTickets,
    meta,
    isFetching: supportTicketsIsFetching,
    error: supportTicketsError = {} as any,
    refetch: refetchSupportTickets,
  } = useSupportTickets({
    filters: {
      page: filterParams.page || 1,
      search: searchName,
      status: filterParams.status?.value || '',
    },
    mapper: Mapper.mapWithTransformer<TicketData[], TicketDataTableItem[]>({
      transformer: SupportTicketsTransformer.tableTransformer(),
    }),
  })

  return (
    <div>
      <div className="flex flex-col gap-6">
        <ListHeader
          Icon={<TicketIcon className="size-10" strokeWidth={1.5} />}
          title="Chamados"
          createButtonText="Abrir chamado"
        />

        <ErrorMessageWrapper
          className="h-[calc(100vh-128px)]"
          statusCode={supportTicketsError?.status}
          refetchFunction={refetchSupportTickets}
        >
          <div className="h-[calc(100vh-128px)] flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <TextField
                LeftIcon={<SearchIcon />}
                placeholder="Pesquisar"
                className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
                defaultValue={filterParams.name || ''}
                onChange={(e: any) => {
                  setSearchName(e.target?.value)
                  setFilterParams((prev) => ({
                    ...prev,
                    name: e.target?.value,
                  }))
                }}
              />

              <ListFilters
                filters={[
                  {
                    className: 'w-auto',
                    onChange: (e: any) =>
                      setFilterParams((params) => ({
                        ...params,
                        status: e.value ? e : undefined,
                      })),
                    defaultValue: filterParams.status || undefined,
                    options: [{ label: 'Todos', value: '' }, ...(statusOptions || [])],
                    placeholder: 'Status',
                  },
                ]}
              />
            </div>

            <Table
              columns={defaultColumns}
              isLoadingData={supportTicketsIsFetching}
              page={filterParams.page || 1}
              rowEmptyValue="--"
              rows={supportTickets}
              totalPages={meta.total_pages}
              totalCount={meta.total}
              hasPagination
              setPage={(p) => setFilterParams((params) => ({ ...params, page: p }))}
            />
          </div>
        </ErrorMessageWrapper>
      </div>
    </div>
  )
}

export default List
