export const billingEventsFiscalDocumentsDefaultColumns = [
  { label: '', value: 'id', hidden: true },
  { label: 'Status', value: 'status' },
  { label: 'Transação', value: 'protocol_number' },
  { label: 'Fatura', value: 'billing_identifier' },
  { label: 'Tipo', value: 'type' },
  { label: 'Detalhes', value: 'details' },
  { label: 'Valor total', value: 'total_amount' },
  { label: 'Data e hora', value: 'created_at' },
  { label: 'Empresa', value: 'client_company' },
  { label: 'Filial da empresa', value: 'client_company_branch' },
  { label: 'Prazo limite recolha', value: 'upload_due_time' },
  { label: 'Upload por', value: 'uploaded_by' },
  { label: 'Ações', value: 'action', className: 'w-9' },
];
