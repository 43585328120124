export default [
  { label: 'Status', value: 'status' },
  { label: 'Transação', value: 'protocol_number' },
  { label: 'Fatura', value: 'bill' },
  { label: 'Tipo', value: 'eventable_type' },
  { label: 'Detalhes', value: 'details' },
  { label: 'Vlr. Total', value: 'total_amount' },
  { label: 'Tarifas', value: 'fee_total_amount' },
  { label: 'Ret. IR', value: 'income_tax_total_amount' },
  { label: 'Cartão', value: 'card' },
  { label: 'Autor', value: 'author' },
  { label: 'Consumidor', value: 'buyer' },
  { label: 'Data e Hora', value: 'created_at' },
];
