import { CompanyData } from "../types/companies/companies.type";

export class CompaniesTransformer {
  static optionsTransformer() {
    return (companies: CompanyData[]) => {
      return companies.map((company) => {
        return {
          value: company.id,
          label: company?.general_information?.fantasy_name,
        };
      });
    };
  }
}
