import { Link } from "react-router-dom";
import { ArrowBigRight, BriefcaseBusiness } from "lucide-react";
import { BillingEventData } from "@/common/types/billing/event.type";
import { formatCurrency, formatDateTime } from "@/common/utils/formatters";
import React from "react";
import { billingEventsFiscalDocumentsStatus } from "../constants/eventsFiscalDocumentsStatusOptions.constant";
import ConsumptionDetails from "@/features/billingEvents/components/ConsumptionDetails";
import RefundDetails from "@/features/billingEvents/components/RefundDetails";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";

export class BillingEventsFiscalDocumentsTransformer {
  static tableTransformer() {
    return (events: BillingEventData[]) => {
      return events.map((event) => {
        return {
          ...event,
          status: event.eventable?.fiscal_document_status ? billingEventsFiscalDocumentsStatus[event.eventable?.fiscal_document_status as keyof typeof billingEventsFiscalDocumentsStatus] : '',
          type: event.eventable_type ? eventableTypeTranslations[event.eventable_type] : '',
          details: eventableDetailsComponent[event.eventable_type] ? React.createElement(eventableDetailsComponent[event.eventable_type], { data: event.eventable }) : '',
          total_amount: event.total_amount ? formatCurrency(event.total_amount) : '',
          client_company: event.eventable?.client_branch?.general_information && (
            <div className="flex items-center gap-2">
              <ImageWithPlaceholder
                placeholderIcon={BriefcaseBusiness}
                className="w-6 h-6"
                iconClassName="w-4 h-4"
                url={event.eventable?.client_branch?.general_information?.company_logo?.url}
              />

              <p>
                {event.eventable?.client_branch?.general_information?.fantasy_name}
              </p>
            </div>
          ),
          client_company_branch: event.eventable?.client_branch?.display_name,
          created_at: formatDateTime(new Date(event.created_at)),
          upload_due_time: 'Não implementado',
          uploaded_by: 'Não implementado',
          action: (
            <Link to={event.id}>
              <ArrowBigRight />
            </Link>
          ),
          _className: event.eventable?.fiscal_document_status === 'rejected' ? 'bg-yellow-op-2' : '',
        };
      });
    };
  }
}

export const eventableDetailsComponent: { [key: string]: React.ComponentType<any>} = {
  'Client::Consumption': ConsumptionDetails,
  'Billing::Refund': RefundDetails,
}

const eventableTypeTranslations: { [key: string]: string; } = {
  'Client::Consumption': 'Consumo',
  'Billing::Refund': 'Estorno',
};