import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import { FileUploader } from "@/common/components/FileUploader";
import Table from "@/common/components/Table";
import { useGoBack } from "@/common/hooks/useGoBack";
import { formatCurrency, formatDate } from "@/common/utils/formatters";
import { getFilenameFromUrl } from "@/common/utils/getFilenameFromUrl";
import FiscalDocumentRejected from "@/features/billing/components/EventsWithFiscalDocumentRequirement/FiscalDocumentRejected";
import BillingResume from "@/features/billing/components/FiscalDocumentGroups/BillingResume";
import UploadFiscalDocument from "@/features/billing/components/FiscalDocumentGroups/UploadFiscalDocument";
import InssuanceData from "@/features/billing/components/InssuanceData";
import { fiscalDocumentFileClassName } from "@/features/billing/constants/fiscalDocumentFileClassName.constant";
import { productsSummaryDefaultColumns } from "@/features/billing/constants/productsSummaryDefaultColumns.constant";
import useBillingFiscalDocumentGroup from "@/features/billing/hooks/useBillingFiscalDocumentGroup";
import useBillingFiscalDocumentGroupTotalizer from "@/features/billing/hooks/useBillingFiscalDocumentGroupTotalizer";
import { ProductsSummaryTransformer } from "@/features/billing/services/productsSummaryTransformer";
import { ChevronLeftIcon, InfoIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const BillingFiscalDocumentGroupsShow = () => {
  const { id } = useParams();
  const goBack = useGoBack();

  const { data: fiscalDocumentGroup } = useBillingFiscalDocumentGroup({ id });

  const fiscalDocumentStatus = fiscalDocumentGroup?.status;

  const { data: fiscalDocumentGroupTotalizer, isFetching: fiscalDocumentGroupTotalizerIsFetching } = useBillingFiscalDocumentGroupTotalizer({ id });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <Button variant="link-white" onClick={() => goBack({ fallback: '/billing-fiscal-document-groups' })}>
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>

          {fiscalDocumentGroup && (
            <h2 className="text-2xl font-bold">
              Fatura {formatDate(new Date(`${fiscalDocumentGroup?.opened_at} `))} - {formatDate(new Date(`${fiscalDocumentGroup?.closed_at} `))}
            </h2>
          )}
        </div>

        {fiscalDocumentGroup && (fiscalDocumentStatus === 'pending' || fiscalDocumentStatus === 'rejected') && (
          <div className="flex items-center gap-3">
            <UploadFiscalDocument groupId={fiscalDocumentGroup.id} />
          </div>
        )}
      </div>

      <Box className="h-[calc((100vh-88px-2rem-1.5rem))] overflow-auto flex gap-6">
        <div className={`flex flex-col gap-6 h-max w-full`}>
          <BillingResume fiscalDocumentGroup={fiscalDocumentGroup} />

          <div>
            <h3>Produtos</h3>

            <Table
              className={twMerge('max-h-[210px]', fiscalDocumentGroupTotalizer?.products_summary?.length === 0 && 'overflow-hidden [&>*]:-mt-20 pt-4')}
              columns={productsSummaryDefaultColumns}
              rows={ProductsSummaryTransformer.tableTransformer()(fiscalDocumentGroupTotalizer?.products_summary || [])}
              isLoadingData={fiscalDocumentGroupTotalizerIsFetching}
              hasPagination={false}
              loadingRows={1}
              page={1}
              totalPages={1}
              bottomContent={(
                <div className="flex justify-between w-full">
                  <p className="text-xs">Total</p>
                  <p className="text-blue-800 text-sm">{formatCurrency(Number(fiscalDocumentGroup?.consumptions_amount) || 0)}</p>
                </div>
              )}
            />
          </div>

          <div className="flex">
            {fiscalDocumentGroup?.fiscal_document && (
              <div className="w-1/2">
                <InssuanceData clientCompanyGeneralInformation={fiscalDocumentGroup?.client_branch?.general_information} />
              </div>
            )}

            <div className="flex flex-col gap-3">
              <h3 className="text-xl font-bold">Nota fiscal</h3>
              {fiscalDocumentGroup?.fiscal_document ? (
                <>
                  {fiscalDocumentStatus === 'rejected' && <FiscalDocumentRejected fiscalDocument={fiscalDocumentGroup?.fiscal_document} />}

                  <FileUploader
                    disabled
                    id="uploaded_fiscal_document"
                    className={twMerge("w-56", fiscalDocumentGroup.status && fiscalDocumentFileClassName[fiscalDocumentGroup.status as keyof typeof fiscalDocumentFileClassName])}
                    fileName={fiscalDocumentGroup?.fiscal_document?.xml?.url ? getFilenameFromUrl(fiscalDocumentGroup?.fiscal_document?.xml?.url) : ''}
                    currentFileUrl={fiscalDocumentGroup?.fiscal_document?.xml?.url}
                  />
                </>
              ) : (
                <div className="flex gap-2">
                  <InfoIcon className="w-[28px]" strokeWidth={1} color="var(--warning)" />

                  <div className="flex flex-col">
                    <p className="text-xs font-bold">O estabelecimento ainda não enviou essa nota.</p>
                    <p className="text-xs italic font-light">Caso o prazo termine, a fatura será cancelada e sua empresa não será debitada.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default BillingFiscalDocumentGroupsShow;
