import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import { FileUploader } from "@/common/components/FileUploader";
import Table from "@/common/components/Table";
import { lineItemsDefaultColumns } from "@/common/constants/lineItemsDefaultColumns.constant";
import { useGoBack } from "@/common/hooks/useGoBack";
import { EventLineItemsTransformer } from "@/common/services/eventLineItemsTransformer";
import { ClientConsumptionLineItemData } from "@/common/types/client/consumptionLineItem.type";
import { formatCurrency } from "@/common/utils/formatters";
import { getFilenameFromUrl } from "@/common/utils/getFilenameFromUrl";
import EventCardDetails from "@/features/billing/components/EventsWithFiscalDocumentRequirement/EventCardDetails";
import EventResume from "@/features/billing/components/EventsWithFiscalDocumentRequirement/EventResume";
import FiscalDocumentRejected from "@/features/billing/components/EventsWithFiscalDocumentRequirement/FiscalDocumentRejected";
import UploadFiscalDocument from "@/features/billing/components/EventsWithFiscalDocumentRequirement/UploadFiscalDocument";
import InssuanceData from "@/features/billing/components/InssuanceData";
import { fiscalDocumentFileClassName } from "@/features/billing/constants/fiscalDocumentFileClassName.constant";
import useBillingEvent from "@/features/billing/hooks/useBillingEvent";
import { ChevronLeftIcon, InfoIcon } from "lucide-react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const BillingEventsWithFiscalDocumentRequirementShow = () => {
  const { id } = useParams();
  const goBack = useGoBack();

  const uploadedFiscalDocumentRef = useRef(null);

  const { data: event, isFetching: eventIsFetching } = useBillingEvent({ id });

  const fiscalDocumentStatus = event?.eventable?.fiscal_document_status;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <Button variant="link-white" onClick={() => goBack({ fallback: '/events-with-fiscal-document-requirement' })}>
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>

          <h2 className="text-2xl font-bold">Transação {event?.protocol_number}</h2>
        </div>

        {event && (fiscalDocumentStatus === 'pending' || fiscalDocumentStatus === 'rejected') && (
          <div className="flex items-center gap-3">
            <UploadFiscalDocument eventId={event.id} />
          </div>
        )}
      </div>

      <Box className="h-[calc((100vh-88px-2rem-1.5rem))] overflow-auto flex gap-6">
        <div className={`flex flex-col gap-6 h-max ${event?.eventable?.fiscal_document ? 'w-1/2' : 'w-full'}`}>
          <EventResume event={event} />

          {event?.eventable?.card && <EventCardDetails card={event.eventable.card} />}

          <div>
            <Table
              className="max-h-[210px]"
              columns={lineItemsDefaultColumns}
              rows={EventLineItemsTransformer.tableTransformer()((event?.eventable?.line_items || []) as unknown as ClientConsumptionLineItemData[]) || []}
              isLoadingData={eventIsFetching}
              hasPagination={false}
              loadingRows={1}
              page={1}
              totalPages={1}
              bottomContent={(
                <div className="flex justify-between w-full">
                  <p className="text-xs">Total</p>
                  <p className="text-blue-800 text-sm">{formatCurrency(Number(event?.total_amount) || 0)}</p>
                </div>
              )}
            />
          </div>

          {fiscalDocumentStatus === 'pending' && (
            <div className="flex flex-col gap-3">
              <h3 className="text-xl font-bold">Nota fiscal</h3>
              <div className="flex gap-2">
                <InfoIcon className="w-[28px]" strokeWidth={1} color="var(--warning)" />

                <div className="flex flex-col">
                  <p className="text-xs font-bold">O estabelecimento ainda não enviou essa nota.</p>
                  <p className="text-xs italic font-light">Caso o prazo termine, a transação será cancelada e sua empresa não será debitada.</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {event?.eventable?.fiscal_document && fiscalDocumentStatus !== 'pending' && (
          <div className="flex flex-col gap-6 w-1/2">
            <InssuanceData clientCompanyGeneralInformation={event?.eventable?.client_branch?.general_information} />

            <div className="flex flex-col gap-3">
              <h3 className="text-xl font-bold">Nota fiscal</h3>

              {fiscalDocumentStatus === 'rejected' && <FiscalDocumentRejected fiscalDocument={event.eventable.fiscal_document} />}

              <FileUploader
                disabled
                ref={uploadedFiscalDocumentRef}
                id="uploaded_fiscal_document"
                className={twMerge("w-full", event.eventable?.fiscal_document_status && fiscalDocumentFileClassName[event.eventable?.fiscal_document_status as keyof typeof fiscalDocumentFileClassName])}
                fileName={event.eventable?.fiscal_document?.xml?.url ? getFilenameFromUrl(event.eventable?.fiscal_document?.xml?.url) : ''}
                currentFileUrl={event.eventable?.fiscal_document?.xml?.url}
              />
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};

export default BillingEventsWithFiscalDocumentRequirementShow;
