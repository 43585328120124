import { privateAxios } from "@/common/api/axios"
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant"
import { Mapper } from "@/common/services/mapper"
import { MetaResponse } from "@/common/types/metaResponse"
import { keepPreviousData, useQuery } from "@tanstack/react-query"

type UseCompaniesProps<T> = {
  filters?: {
    search?: string
    page?: number
    kind?: string
    paymentModality?: string
    commercialManagerId?: string
  }
  mapper?: (data: any) => T,
}

export const useCompanies = <T,>({
  filters: { search = '', page, kind, paymentModality, commercialManagerId } = {},
  mapper = Mapper.default<T>(),
}: UseCompaniesProps<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: ['companies', search, page, kind, paymentModality, commercialManagerId],
    queryFn: () => {
      return privateAxios.get('/client/companies', {
        params: {
          search,
          page,
          'q[kind_eq]': kind,
          'q[payment_modality_eq]': paymentModality,
          'q[commercial_manager_id_eq]': commercialManagerId
        }
      })
    },
    select: (data: { data: { data: any[], meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, isPending, ...rest }
}
