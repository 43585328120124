import ListHeader from "@/common/components/ListHeader";
import { AreaChart } from "lucide-react";
import { useState } from "react";
import Table from "@/common/components/Table";
import { DateRange } from "react-day-picker";
import { useMovementsTotalizer } from "../../hooks/useMovementsTotalizer";
import { dateToISOStingTransformer } from "@/common/utils/dateTransformer";
import { totalizerFeesColumns, totalizerProductsColumns } from "../../constants/movementsTotalizerTableColumns.constant";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import ResumeList from "../../components/ResumeList";
import { MovementsTotalizerData } from "../../types/movementTotalizer.type";
import { MovementsTotalizerTransformer } from "../../services/movementsTotalizerTransformer";
import { ErrorMessageWrapper } from "@/common/components/ErrorMessageWrapper";

const ProductPricesList = () => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });

  const {
    data: movementsTotalizer,
    isFetching: movementsTotalizerIsFetching,
    error: movementsTotalizerError = {} as any,
    refetch: refetchMovementsTotalizer,
  } = useMovementsTotalizer<MovementsTotalizerData>({
    filters: {
      startDate: dateToISOStingTransformer(date?.from),
      endDate: dateToISOStingTransformer(date?.to),
    },
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<AreaChart className="w-10 h-10" strokeWidth={1.5} />}
          title="Totalizadores"
        />
      </div>

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={movementsTotalizerError?.status}
        refetchFunction={refetchMovementsTotalizer}
      >
        <div className="flex flex-col gap-6 max-h-[calc(100vh-128px)] overflow-y-auto pr-1">
          <div className="flex">
            <DatePickerWithRange
              date={date}
              setDate={setDate}
            />
          </div>
          <ResumeList
            data={movementsTotalizer}
          />

          <div className="space-y-2">
            <p>Produtos</p>
            <Table
              className={`max-h-[280px] min-h-[${Math.min(
                100 + (movementsTotalizer?.product_movements?.length || 0 * 56),
                256,
              )}px]`}
              columns={totalizerProductsColumns}
              isLoadingData={movementsTotalizerIsFetching}
              rows={MovementsTotalizerTransformer.productsTableTransformer()(movementsTotalizer?.product_movements) || []}
              rowEmptyValue="--"
              page={1}
              totalPages={1}
              totalCount={movementsTotalizer?.product_movements?.length}
            />
          </div>

          <div className="space-y-2">
            <p>Tarifas</p>
            <Table
              className={`max-h-[280px] min-h-[${Math.min(
                100 + (movementsTotalizer?.product_movements?.length || 0 * 56),
                256,
              )}px]`}
              columns={totalizerFeesColumns}
              isLoadingData={movementsTotalizerIsFetching}
              rows={MovementsTotalizerTransformer.feesTableTransformer()(movementsTotalizer?.fees) || []}
              rowEmptyValue="--"
              page={1}
              totalPages={1}
              totalCount={movementsTotalizer?.fees?.length}
            />
          </div>
        </div>
      </ErrorMessageWrapper>
    </div>
  );
};

export default ProductPricesList;