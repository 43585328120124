import Table from '@/common/components/Table';
import TextField from '@/common/components/TextField';
import { DatePickerWithRange } from '@/common/components/ui/dateRangePicker';
import { useFilterDate } from '@/common/hooks/useFilterDate';
import { useFilterParams } from '@/common/hooks/useFilterParams';
import { Mapper } from '@/common/services/mapper';
import eventsColumns from '@/features/users/constants/eventsColumns.constant';
import { useEstablishmentUserEvents } from '@/features/users/hooks/useEstablishmentUserEvents';
import { EstablishmentUserEventTransformer } from '@/features/users/services/establishmentUserEventsTransformer';
import { SearchIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

const EventsTab = () => {
  const { id: userId } = useParams();

  const { filterParams, setFilterParams } = useFilterParams();

  const { date, formattedDate } = useFilterDate(filterParams);

  const { data: events, meta, isFetching: establishmentUserEventsIsFetching } = useEstablishmentUserEvents({
    userId,
    filters: {
      page: filterParams?.page || 1,
      search: filterParams?.search || '',
      dateFrom: formattedDate.from,
      dateTo: formattedDate.to,
    },
    mapper: Mapper.mapWithTransformer({
      transformer: EstablishmentUserEventTransformer.tableTransformer(),
    }),
  });

  return (
    <div className="h-[calc((100vh-104px-1.5rem))] flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <TextField
          LeftIcon={<SearchIcon />}
          placeholder="Pesquisar"
          className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
          onChange={(e: any) => setFilterParams((params) => ({ ...params, search: e.target.value }))}
          defaultValue={filterParams?.search}
        />

        <div className="flex items-center gap-2">
          <DatePickerWithRange
            date={date}
            setDate={(date) => setFilterParams((params) => ({ ...params, date }))}
          />
        </div>
      </div>

      <Table
        columns={eventsColumns}
        isLoadingData={establishmentUserEventsIsFetching}
        rows={events}
        rowEmptyValue="--"
        hasPagination
        page={filterParams.page || 1}
        setPage={(p) => setFilterParams((params) => ({ ...params, page: p }))}
        totalPages={meta.total_pages}
        totalCount={meta.total}
      />
    </div>
  );
};

export default EventsTab;
