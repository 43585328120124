import { checkIsAcceptedFile } from "@/common/utils/fileValidator";
import { Download, Upload } from "lucide-react";
import { FormEventHandler, forwardRef } from "react"
import { twMerge } from "tailwind-merge";

export interface FileUploaderProps extends React.HTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string,
  name?: string,
  title?: string,
  fileName?: string
  currentFileUrl?: string,
  accept?: string
  className?: string
  handleChange?: (file: File) => void
  disabled?: boolean
}

export const FileUploader = forwardRef<HTMLInputElement, FileUploaderProps>(({
  id,
  label,
  name,
  title = 'Anexe um arquivo',
  fileName,
  currentFileUrl,
  className,
  accept = '',
  handleChange,
  onChange,
  ...props
}, ref) => {
  const handleOnChange: FormEventHandler<HTMLInputElement> = (event) => {
    onChange?.(event)

    if (handleChange) {
      const file = (event.target as HTMLInputElement & { files: FileList }).files?.[0]

      if (!file || !checkIsAcceptedFile(file, accept)) {
        return
      }

      handleChange(file)
    }
  }

  return (
    <div className="flex flex-col items-start">
      {label && <p className="text-xs mb-1">{label}</p>}
      {currentFileUrl
        ? (
          <label className={twMerge("flex flex-col justify-between p-4 h-[100px] bg-white-200 rounded-lg", className)} htmlFor="file_input" >
            <div className="w-full flex justify-start italic text-xs overflow-hidden ">{fileName || 'Documento'}</div>
            <div className="w-full flex justify-end"><a href={currentFileUrl} download={fileName} target="_blank" ><Download /></a></div>
          </label>
        )
        : (
          <>
            <label className={twMerge("flex flex-col justify-center border border-neutral-3 w-[180px] h-[100px] gap-2 items-center bg-neutral-1 cursor-pointer rounded-lg", className)} htmlFor={id || "file_input"} >
              <p className="text-xs">{title}</p>
              <Upload />
              <p className="text-xs italic">Ou arraste até aqui</p>
            </label>
          </>
        )
      }

      <input type="file" ref={ref} name={name} className="hidden" id={id || "file_input"} accept={accept} onChange={handleOnChange} {...props} />

    </div>
  )
})
