import usePrivateAxios from "@/common/api/usePrivateAxios";
import { ErrorResponse } from "@/common/types/errorResponse";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

interface UseUploadFiscalDocumentForConsumption {
  id: string;
  onSuccess?: (response: AxiosResponse) => void;
  onError?: (error: ErrorResponse) => void;
}

const useBillingFiscalDocumentGroupUploadFiscalDocument = ({ id, onSuccess, onError }: UseUploadFiscalDocumentForConsumption) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['billingFiscalDocumentGroupUploadFiscalDocument'],
    mutationFn: async (file: Blob) => {
      return await privateAxios.putForm(`/billing/fiscal_document_groups/${id}/upload_fiscal_document`, {
        fiscal_document_group: {
          fiscal_document_attributes: {
            xml: file,
          }
        },
      });
    },
    onSuccess,
    onError,
  });
};

export default useBillingFiscalDocumentGroupUploadFiscalDocument;
