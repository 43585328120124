import { AxiosError } from 'axios'
import { Dispatch, SetStateAction } from 'react'
import { FieldValues, Path, UseFormSetError } from 'react-hook-form'
import { ErrorsMapper } from '../services/errorsMapper'

export class FormTransformers {
  static errorsTransformer<T extends FieldValues>({
    setError,
    setMessages,
    transformer,
  }: {
    setError: UseFormSetError<T>
    setMessages?: Dispatch<SetStateAction<string[] | boolean>>
    transformer?: (errors: Record<string, string[]>) => any[]
  }) {
    return (error: AxiosError) => {
      const { errors } = error?.response?.data as { errors: Record<string, string[]> }

      if (!errors || !Object.keys(errors).length) {
        return
      }

      const transformedErrors = transformer?.(errors) || ErrorsMapper.default()(errors)

      if (transformedErrors.length) {
        transformedErrors.map((error) => {
          const key = Object.keys(error)?.[0]
          setError(key as Path<T>, { message: error[key] })
        })
      }

      if (errors?.base?.length && setMessages) {
        setMessages(errors.base)
      }
    }
  }
}
