import { formatCnpj } from "@/common/utils/formatters";
import { GeneralInformationData } from "@/features/company/types/company.type";

const InssuanceData = ({ clientCompanyGeneralInformation }: { clientCompanyGeneralInformation?: GeneralInformationData }) => {
  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-xl font-bold">Dados para emissão</h3>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <p className="italic font-light text-xs">CNPJ</p>
          <p className="font-bold text-md">{clientCompanyGeneralInformation?.cnpj ? formatCnpj(clientCompanyGeneralInformation?.cnpj) : 'Não informado'}</p>
        </div>

        <div className="flex flex-col gap-1">
          <p className="italic font-light text-xs">Razão social</p>
          <p className="font-bold text-md">{clientCompanyGeneralInformation?.official_name || 'Não informado'}</p>
        </div>

        <div className="flex flex-col gap-1">
          <p className="italic font-light text-xs">Inscrição estadual</p>
          <p className="font-bold text-md">{clientCompanyGeneralInformation?.state_inscription || 'Não informado'}</p>
        </div>
      </div>
    </div>
  );
};

export default InssuanceData;